.input {
  &:focus {
    outline: none;
    -webkit-appearance: none;
  }
}

input,
textarea,
select{
  border: 1px solid #d7dfef;
  background-color: #fff;
  width: 100%;
  color: $dark-blue;
  font-weight: 400;
  padding: 0 18px 0 18px;
  font-size: $input-fz;
  outline: none;
  border-radius: 5px;
  font-family: $proxima-nova;

  &:focus {
    box-shadow: none;
    border: 1px solid $dark-blue !important;
  }
}

input[type='checkbox']{
  width: 20px !important;
  height: 20px;
}

@mixin placeholder-style{
  font-size: $input-fz !important;
  color: #ABAEB5 !important;
  font-family: $proxima-nova !important;
  //font-style: normal !important;
  font-weight: 300 !important;
}

input::placeholder {
  @include placeholder-style;
}

::-webkit-input-placeholder {
  @include placeholder-style;
}
::-moz-placeholder {
  @include placeholder-style;
}
:-ms-input-placeholder {
  @include placeholder-style;
}
:-moz-placeholder {
  @include placeholder-style;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active,
select:-internal-autofill-selected{
  -webkit-appearance: menulist-button;
  background-color: #fff;
  color: $dark-blue !important;
  -webkit-box-shadow: 0 0 0px 100px white inset;
  -webkit-text-fill-color: $dark-blue;
}

input[type="date"],
select,
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"]{
  padding-top: 0;
  height: 40px;
  line-height: 40px;
  color: $dark-blue;
}


textarea,{
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 110px;
}

input:disabled,
textarea:disabled,
select:disabled,
input:disabled,
input:disabled:hover,
input:disabled:active,
input:disabled:focus {
  background: #F8F8F8 !important;
  border-color: #F8F8F8 !important;
}

input[type="radio"]{
  width: 20px;
  height: 20px;
}
