/* line 1, app/styles/sass/main.css */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
/* line 22, app/styles/sass/main.css */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

/* line 26, app/styles/sass/main.css */
body {
  line-height: 1; }

/* line 29, app/styles/sass/main.css */
ol, ul {
  list-style: none; }

/* line 32, app/styles/sass/main.css */
blockquote, q {
  quotes: none; }

/* line 35, app/styles/sass/main.css */
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

/* line 40, app/styles/sass/main.css */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* line 44, app/styles/sass/main.css */
button, .btn, a, .link {
  touch-action: manipulation;
  cursor: pointer; }

/* line 47, app/styles/sass/main.css */
button:focus, .btn:focus, a:focus, .link:focus {
  outline: 0 !important; }

/* line 50, app/styles/sass/main.css */
a {
  color: #06adef;
  text-decoration: none; }

/* line 54, app/styles/sass/main.css */
a:active, a:focus, a:hover {
  text-decoration: underline;
  -webkit-tap-highlight-color: transparent; }

/* line 58, app/styles/sass/main.css */
button {
  position: relative;
  overflow: hidden;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.01em;
  font-weight: 400;
  text-align: center;
  align-items: center;
  outline: 0;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: all .22s ease;
  -webkit-tap-highlight-color: transparent; }

/* line 73, app/styles/sass/main.css */
button:focus, button:active {
  outline: 0;
  border: none;
  text-decoration: none !important;
  box-shadow: none; }

/* line 78, app/styles/sass/main.css */
button:hover {
  text-decoration: none !important; }

/* line 80, app/styles/sass/main.css */
button span.ripple {
  position: absolute;
  display: block;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.23); }

/* line 89, app/styles/sass/main.css */
.input:focus {
  outline: none;
  -webkit-appearance: none; }

/* line 93, app/styles/sass/main.css */
input,
textarea,
select {
  border: 1px solid #d7dfef;
  background-color: #fff;
  width: 100%;
  color: #253246;
  font-weight: 400;
  padding: 0 18px 0 18px;
  font-size: 16px;
  outline: none;
  border-radius: 5px;
  font-family: "Proxima Nova", sans-serif; }

/* line 106, app/styles/sass/main.css */
input:focus,
textarea:focus,
select:focus {
  box-shadow: none;
  border: 1px solid #253246 !important; }

/* line 112, app/styles/sass/main.css */
input[type='checkbox'] {
  width: 20px !important;
  height: 20px; }

/* line 116, app/styles/sass/main.css */
input::placeholder {
  font-size: 16px !important;
  color: #ABAEB5 !important;
  font-family: "Proxima Nova", sans-serif !important;
  font-weight: 300 !important; }

/* line 122, app/styles/sass/main.css */
::-webkit-input-placeholder {
  font-size: 16px !important;
  color: #ABAEB5 !important;
  font-family: "Proxima Nova", sans-serif !important;
  font-weight: 300 !important; }

/* line 128, app/styles/sass/main.css */
::-moz-placeholder {
  font-size: 16px !important;
  color: #ABAEB5 !important;
  font-family: "Proxima Nova", sans-serif !important;
  font-weight: 300 !important; }

/* line 134, app/styles/sass/main.css */
:-ms-input-placeholder {
  font-size: 16px !important;
  color: #ABAEB5 !important;
  font-family: "Proxima Nova", sans-serif !important;
  font-weight: 300 !important; }

/* line 140, app/styles/sass/main.css */
:-moz-placeholder {
  font-size: 16px !important;
  color: #ABAEB5 !important;
  font-family: "Proxima Nova", sans-serif !important;
  font-weight: 300 !important; }

/* line 146, app/styles/sass/main.css */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active,
select:-internal-autofill-selected {
  -webkit-appearance: menulist-button;
  background-color: #fff;
  color: #253246 !important;
  -webkit-box-shadow: 0 0 0px 100px white inset;
  -webkit-text-fill-color: #253246; }

/* line 162, app/styles/sass/main.css */
input[type="date"],
select,
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"] {
  padding-top: 0;
  height: 40px;
  line-height: 40px;
  color: #253246; }

/* line 173, app/styles/sass/main.css */
textarea {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 110px; }

/* line 178, app/styles/sass/main.css */
input:disabled,
textarea:disabled,
select:disabled,
input:disabled,
input:disabled:hover,
input:disabled:active,
input:disabled:focus {
  background: #F8F8F8 !important;
  border-color: #F8F8F8 !important; }

/* line 188, app/styles/sass/main.css */
input[type="radio"] {
  width: 20px;
  height: 20px; }

/* line 192, app/styles/sass/main.css */
body {
  min-height: 100vh;
  min-height: -webkit-fill-available; }

/* line 196, app/styles/sass/main.css */
html {
  height: -webkit-fill-available; }

/* line 199, app/styles/sass/main.css */
html, body {
  min-height: 100%; }

/* line 202, app/styles/sass/main.css */
body {
  font-family: "Proxima Nova", sans-serif;
  line-height: 1.5;
  color: #788291;
  font-size: 14px;
  min-width: unset;
  width: 100%;
  min-height: 100%; }

/* line 211, app/styles/sass/main.css */
img {
  height: auto;
  max-width: 100%; }

/* line 215, app/styles/sass/main.css */
* {
  box-sizing: border-box; }

/* line 218, app/styles/sass/main.css */
a, a:link {
  color: #06adef; }

/* line 221, app/styles/sass/main.css */
.queue--phantom-block, .queue--clients-list, .queue--controls {
  width: 100%; }

/* line 224, app/styles/sass/main.css */
.queue--header {
  text-align: center;
  margin-bottom: 30px;
  padding: 0 20px; }

/* line 228, app/styles/sass/main.css */
.queue--header-title {
  font-size: 42px;
  font-weight: 500;
  color: #06adef;
  line-height: 1.2;
  margin-bottom: 5px; }

@media (max-width: 1023px) {
  /* line 235, app/styles/sass/main.css */
  .queue--header-title {
    font-size: 36px; } }

@media (max-width: 767px) {
  /* line 238, app/styles/sass/main.css */
  .queue--header-title {
    font-size: 32px; } }

/* line 240, app/styles/sass/main.css */
.queue--header-subtitle {
  font-size: 16px;
  color: #253246; }

/* line 244, app/styles/sass/main.css */
.queue--component {
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 100vh; }

/* line 252, app/styles/sass/main.css */
.queue--arrow-right-icon {
  position: absolute;
  top: 5px;
  left: -12px; }

/* line 257, app/styles/sass/main.css */
.queue--approximateli-icon {
  margin-right: 12px; }

/* line 260, app/styles/sass/main.css */
.queue--clients-list {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 10px 0 80px; }

/* line 268, app/styles/sass/main.css */
.queue--clients-list__item {
  position: relative;
  flex: 1;
  width: 100%;
  margin: 4px 0;
  padding: 30px 0;
  text-align: center;
  min-width: 86px;
  max-width: 140px; }

/* line 277, app/styles/sass/main.css */
.queue--clients-list__item .queue--arrow-right-icon {
  display: none; }

/* line 279, app/styles/sass/main.css */
.queue--clients-list__item-apr-time, .queue--clients-list__item-icon-stopwatch {
  display: none; }

/* line 281, app/styles/sass/main.css */
.queue--clients-list__item-icon-user {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  padding-bottom: 12px;
  margin-bottom: 12px; }

/* line 289, app/styles/sass/main.css */
.queue--clients-list__item-icon-user svg {
  height: 15px; }

/* line 291, app/styles/sass/main.css */
.queue--clients-list__item-icon-user svg circle,
.queue--clients-list__item-icon-user svg path {
  stroke: #ABBCCC; }

/* line 294, app/styles/sass/main.css */
.queue--clients-list__item:nth-child(4n+4) .queue--arrow-right-icon {
  display: inline-block; }

/* line 296, app/styles/sass/main.css */
.queue--clients-list__item-user-num {
  color: #ABBCCC;
  font-size: 14px;
  line-height: 1;
  height: 24px;
  margin-bottom: 12px; }

/* line 302, app/styles/sass/main.css */
.queue--clients-list__item-icon-stopwatch, .queue--clients-list__item-apr-time {
  position: absolute;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1; }

/* line 312, app/styles/sass/main.css */
.queue--clients-list__item-icon-stopwatch {
  bottom: -24px;
  width: 48px;
  height: 48px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(6px);
  border-radius: 50%; }

/* line 319, app/styles/sass/main.css */
.queue--clients-list__item-apr-time {
  bottom: -50px;
  font-size: 16px; }

@media (max-width: 660px) {
  /* line 323, app/styles/sass/main.css */
  .queue--clients-list__item-apr-time {
    bottom: -78px; } }

/* line 325, app/styles/sass/main.css */
.queue--clients-list__item-apr-time-title {
  position: relative;
  min-width: 5px;
  min-height: 16px; }

@media (max-width: 660px) {
  /* line 330, app/styles/sass/main.css */
  .queue--clients-list__item-apr-time-title {
    display: block;
    width: 100%; } }

/* line 333, app/styles/sass/main.css */
.queue--clients-list__item-apr-time-title_content {
  position: absolute;
  right: calc(100% + 50px);
  white-space: nowrap;
  top: 0; }

@media (max-width: 1023px) {
  /* line 339, app/styles/sass/main.css */
  .queue--clients-list__item-apr-time-title_content {
    white-space: normal;
    text-align: right;
    width: 270px; } }

@media (max-width: 660px) {
  /* line 344, app/styles/sass/main.css */
  .queue--clients-list__item-apr-time-title_content {
    display: block;
    position: static;
    text-align: center;
    width: 100%;
    padding-bottom: 5px; } }

/* line 350, app/styles/sass/main.css */
.queue--clients-list__item--current {
  min-width: 100px;
  border: 1px solid #ABBCCC;
  box-sizing: border-box;
  border-radius: 4px; }

/* line 355, app/styles/sass/main.css */
.queue--clients-list__item--current:before, .queue--clients-list__item--current:after {
  content: ' ';
  display: block;
  width: 42vw;
  max-width: 550px;
  height: 1px;
  background: #E7ECF7;
  position: absolute;
  top: 65px; }

/* line 364, app/styles/sass/main.css */
.queue--clients-list__item--current:after {
  left: calc(100% + 12px); }

/* line 366, app/styles/sass/main.css */
.queue--clients-list__item--current:before {
  right: calc(100% + 12px); }

/* line 368, app/styles/sass/main.css */
.queue--clients-list__item--current .queue--clients-list__item-icon-user svg {
  height: 23px; }

/* line 370, app/styles/sass/main.css */
.queue--clients-list__item--current .queue--clients-list__item-icon-user svg circle,
.queue--clients-list__item--current .queue--clients-list__item-icon-user svg path {
  stroke: #253246; }

/* line 373, app/styles/sass/main.css */
.queue--clients-list__item--current .queue--clients-list__item-apr-time,
.queue--clients-list__item--current .queue--clients-list__item-icon-stopwatch {
  display: flex; }

@media (max-width: 660px) {
  /* line 377, app/styles/sass/main.css */
  .queue--clients-list__item--current .queue--clients-list__item-apr-time {
    flex-wrap: wrap;
    width: 320px;
    left: 50%;
    transform: translateX(-50%); } }

/* line 382, app/styles/sass/main.css */
.queue--clients-list__item--current .queue--clients-list__item-user-num {
  font-size: 16px;
  font-weight: bold; }

/* line 386, app/styles/sass/main.css */
.queue--clients-list__item--current .queue--clients-list__item-user-num,
.queue--clients-list__item--current .queue--clients-list__item-apr-time-val {
  color: #06adef; }

/* line 390, app/styles/sass/main.css */
.queue--clients-list__item--current ~ .queue--clients-list__item .queue--clients-list__item-user-num {
  color: #253246; }

/* line 393, app/styles/sass/main.css */
.queue--controls-bar {
  text-align: center; }

/* line 396, app/styles/sass/main.css */
.samesite-cookies__popup {
  position: relative;
  max-width: 420px;
  margin: 0 auto;
  padding: 40px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
  box-shadow: 2px 2px 15px 0 rgba(45, 51, 72, 0.1);
  text-align: center;
  background: #ffffff; }

/* line 407, app/styles/sass/main.css */
.samesite-cookies__popup-title {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600; }

/* line 411, app/styles/sass/main.css */
.samesite-cookies__popup-text {
  margin-bottom: 20px;
  font-size: 16px; }

/* line 414, app/styles/sass/main.css */
.samesite-cookies__popup-btn {
  min-width: 160px;
  background: #06adef;
  color: #ffffff;
  height: 40px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold; }

/* line 422, app/styles/sass/main.css */
.samesite-cookies__popup-link {
  display: block;
  margin: 15px auto 0;
  font-size: 16px;
  background: none;
  border: none;
  padding: 0; }
