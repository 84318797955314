.samesite-cookies {
  &__popup {
    position: relative;
    max-width: 420px;
    margin: 0 auto;
    padding: 40px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 5px;
    box-shadow: 2px 2px 15px 0 rgba(45, 51, 72, 0.1);
    text-align: center;
    background: #ffffff;

    &-title {
      margin-bottom: 15px;
      font-size: 18px;
      font-weight: 600;
    }

    &-text {
      margin-bottom: 20px;
      font-size: 16px;
    }

    &-btn {
      min-width: 160px;
      background: $primary-blue;
      color: #ffffff;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      font-weight: bold;
    }

    &-link {
      display: block;
      margin: 15px auto 0;
      font-size: 16px;
      background: none;
      border: none;
      padding: 0;
    }
  }
}