// use in uikit.scss

//font styles
$gilroy: "Gilroy", sans-serif;
$proxima-nova: "Proxima Nova", sans-serif;

// Responsiveness breakpoints
$fullHD: 1920px;
$lap: 1365px;
$tab768: 768px;
$tab: 1023px;
$mob: 767px;
$mob480: 480px;

// Colors
$primary-blue: #06adef;
$primary-red: #ff3259;
$dark-blue: #253246;
$brand-grey: #ABBCCC;
$secondary-text-color: #788291;
$white: #ffffff;
$second-brand-color: #f1f6ff;
$social-link-color: #ABBCCC;
$outline-grey: #D7DFEF;
$green: #00D38B;

$alert-success: #D5E0F0;
$alert-danger: #FDEAEE;


// Fonts size
$h1: 40px;
$h2: 30px;
$h3: 20px;
$h4: 18px;
$main-text: 14px;
$small-text: 12px;
$input-fz: 16px;

// Font weight
$light: 300;
$normal: 400;
$semibold: 500;
$bold: 700;


@mixin break-min($point) {
  @media (min-width: $point) {
    @content;
  }
}

@mixin break-max($point) {
  @media (max-width: $point) {
    @content;
  }
}

@mixin transition{
  transition: all .22s ease;
}