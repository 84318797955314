.queue{
  &--wrapper{

  }

  &--phantom-block,
  &--clients-list,
  &--controls{
    width: 100%;
  }

  &--header{
    text-align: center;
    margin-bottom: 30px;
    padding: 0 20px;

    &-title{
      font-size: 42px;
      font-weight: 500;
      color: $primary-blue;
      line-height: 1.2;
      margin-bottom: 5px;

      @include break-max($tab){
        font-size: 36px;
      }
      @include break-max($mob){
        font-size: 32px;
      }
    }
    &-subtitle{
      font-size: 16px;
      color: $dark-blue;
    }
  }

  &--component{
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 100vh;
  }

  &--arrow-right-icon{
    position: absolute;
    top: 5px;
    left: -12px;
  }

  &--approximateli-icon{
    margin-right: 12px;
  }

  &--clients-list{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    overflow: hidden;
    margin-bottom: 20px;
    padding: 10px 0 80px;

    &__item{
      position: relative;
      flex: 1;
      width: 100%;
      margin: 4px 0;
      padding: 30px 0;
      text-align: center;
      min-width: 86px;
      max-width: 140px;

      .queue--arrow-right-icon{
        display: none;
      }

      &-apr-time,
      &-icon-stopwatch{
        display: none;
      }

      &-icon-user{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 100%;
        padding-bottom: 12px;
        margin-bottom: 12px;

        svg{
          height: 15px;

          circle,
          path{
            stroke: $brand-grey;
          }
        }
      }

      &:nth-child(4n+4){
        .queue--arrow-right-icon{
          display: inline-block;
        }
      }

      &-user-num{
        color: $brand-grey;
        font-size: 14px;
        line-height: 1;
        height: 24px;
        margin-bottom: 12px;
      }

      &-icon-stopwatch,
      &-apr-time{
        position: absolute;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 1;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 1;
      }

      &-icon-stopwatch{
        bottom: -24px;
        width: 48px;
        height: 48px;
        background: rgba(#fff, 0.7);
        backdrop-filter: blur(6px);
        border-radius: 50%;
      }

      &-apr-time{
        bottom: -50px;
        font-size: 16px;
        @include break-max(660px){
          bottom: -78px;
        }

        &-title{
          position: relative;
          min-width: 5px;
          min-height: 16px;
          @include break-max(660px){
            display: block;
            width: 100%;
          }

          &_content{
            position: absolute;
            right: calc(100% + 50px);;
            white-space: nowrap;
            top: 0;

            @include break-max($tab){
              white-space: normal;
              text-align: right;
              width: 270px;
            }

            @include break-max(660px){
              display: block;
              position: static;
              text-align: center;
              width: 100%;
              padding-bottom: 5px;
            }
          }
        }
      }

      //current
      &--current{
        min-width: 100px;
        border: 1px solid $brand-grey;
        box-sizing: border-box;
        border-radius: 4px;

        &:before,
        &:after{
          content: ' ';
          display: block;
          width: 42vw;
          max-width: 550px;
          height: 1px;
          background: #E7ECF7;
          position: absolute;
          top: 65px;
        }
        &:after{
          left: calc(100% + 12px);
        }
        &:before{
          right: calc(100% + 12px);
        }

        .queue--clients-list__item-icon-user{
          svg{
            height: 23px;
            circle,
            path{
              stroke: $dark-blue;
            }
          }
        }

        .queue--clients-list__item-apr-time,
        .queue--clients-list__item-icon-stopwatch{
          display: flex;
        }

        .queue--clients-list__item-apr-time{
          @include break-max(660px){
            flex-wrap: wrap;
            width: 320px;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        .queue--clients-list__item-user-num{
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }

  &--clients-list__item--current{
    .queue--clients-list__item-user-num,
    .queue--clients-list__item-apr-time-val{
      color: $primary-blue;
    }
  }

  &--clients-list__item--current ~ .queue--clients-list__item{
    .queue--clients-list__item-user-num{
      color: $dark-blue;
    }
  }

  &--controls-bar{
    text-align: center;
  }
}