@import "vars";
@import "ui-kit--buttons";
@import "ui-kit--typography";
@import "ui-kit--forms";

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}

html, body{
  min-height: 100%;
}

body{
  font-family: $proxima-nova;
  line-height: 1.5;
  color: $secondary-text-color;
  font-size: $main-text;
  min-width: unset;
  width: 100%;
  min-height: 100%;
}

img {
  height: auto;
  max-width: 100%;
}

* {
  box-sizing: border-box;
}

a, a:link{
  color: $primary-blue;
}