button, .btn, a, .link{
  touch-action: manipulation;
  cursor: pointer;
  //user-select: none;
  //-webkit-tap-highlight-color: transparent;
  &:focus{
    outline: 0 !important;
  }
}

a {
  color: $primary-blue;
  text-decoration: none;
}

a:active,a:focus,a:hover {
  text-decoration: underline;
  -webkit-tap-highlight-color: transparent;
}

button {
  position: relative;
  overflow: hidden;
  font-size: $main-text;
  //min-height: 45px;
  line-height: 1;
  letter-spacing: 0.01em;
  //font-family: $gilroy;
  font-weight: $normal;
  text-align: center;
  //display: inline-flex;
  align-items: center;
  //padding: 12px 35px;
  //border-radius: 50px;
  outline: 0;
  border: none;
  cursor: pointer;
  text-decoration: none;
  @include transition;
  -webkit-tap-highlight-color: transparent;

  &:focus,
  &:active {
    outline: 0;
    border: none;
    text-decoration: none !important;
    box-shadow: none;
  }
  &:hover{
    text-decoration: none !important;
  }

  span.ripple {
    position: absolute;
    display: block;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    width: 0;
    height: 0;
    background-color: rgba(255, 255, 255, 0.23);
  }
}



